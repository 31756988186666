// React
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Stylesheets
import './App.css';
import './css/Anim.css';
import './fonts/index.css';

// Components
import Home from "./pages/home/Home";
import Admin from "./pages/admin/Admin";
import {useState} from "react";
import UpdateBeers from "./pages/admin/updateBeers/UpdateBeers";
import UpdateEvents from "./pages/admin/updateEvents/UpdateEvents";
import UpdateHours from "./pages/admin/hours/updateHours";
import UpdateHeroImages from "./pages/admin/updateHeroImages/UpdateHeroImages";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import UpdateQuiz from "./pages/admin/updateQuiz/UpdateQuiz";

function App() {
  let [currentUser, setCurrentUser] = useState(null);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/*"} element={<Home />} />
          <Route exact path={"/terms"} element={<Terms />} />
          <Route exact path={"/privacy"} element={<Privacy />} />
          <Route exact path={"/admin"} element={<Admin user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/beers"} element={<UpdateBeers user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/events"} element={<UpdateEvents user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/hours"} element={<UpdateHours user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/hero"} element={<UpdateHeroImages user={currentUser} setUser={setCurrentUser} />} />
          <Route exact path={"/admin/quiz"} element={<UpdateQuiz user={currentUser} setUser={setCurrentUser} />} />
        </Routes>
      </Router>
    </div>
    );
  }

  export default App;
