import './beerCard.css';
import './beerCardMobile.css';
import './beerCardTablet.css';
import Beer from "../../../objects/Beer";

const BeerCard = (props) => {
    let open = props.open;
    let setOpen = props.setOpen;
    let beerCard = props.beerCard;
    let setBeerCard = props.setBeerCard;

    const closeCard = () => {
        setOpen(false)
        setTimeout(() => {
            setBeerCard(new Beer('','', '', '', '', '', '', '', '', '', ''))
        }, 500)
    }

    return (
        <div className={'beerCardContainer'} style={{opacity: open ? '100%' : '0%', pointerEvents: open ? 'visiblePainted' : 'none'}} onClick={() => {
            closeCard()
        }}>
            <div className={'beerCardInnerContainer'} style={{marginTop: open ? '0vh' : '200vh'}} onClick={(e) => {
                e.stopPropagation();
            }}>
                <div className={'beerCardCloseButton'} onClick={() => {
                    closeCard()
                }}>
                    x
                </div>
                <img className={'beerCardImg'} src={beerCard.imageUrl}/>
                <h1 className={'beerCardName'}>{beerCard.name}</h1>
                <div className={'beerCardBrewery'}>{beerCard.brewery}</div>
                <div className={'beerCardBrewery beerCardBreweryLocation'}>{beerCard.breweryLocation}</div>
                <div className={'beerCardBrewery beerCardBreweryCountry'}>{beerCard.breweryCountry}</div>
                <div className={'beerCardDescription'}>{beerCard.description}</div>
                <div className={'beerCardInfo'}>
                    <div className={'beerCardAbv'}>
                        {beerCard.abv}%
                    </div>
                    <div className={'beerCardStyle'}>
                        {beerCard.style}
                    </div>
                </div>
                <button onClick={() => {
                    window.open(`${beerCard.pageUrl}`)
                }} className={'untappdBtn'}>OPEN IN UNTAPPD</button>
            </div>
        </div>
    )
}

export default BeerCard;