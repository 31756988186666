// Stylesheets
import './events.css'
import './eventsMobile.css'

// React
import {useEffect, useState} from "react";

// Functions
import {randomIntFromInterval} from "../../func/randomNumber";
import setRndRotations from "../../func/getRandomRotations";

// Firebase
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {db} from "../../firebase";

// Objects
import {posterConverter} from "../../objects/Poster";

// Imgs
import {PosterTexture1, PosterTexture2, PosterTexture3, PosterTexture4, PosterTexture5} from "../../imgs";

import useWindowDimensions from "../../func/getWindowDimensions";

const Events = (props) => {
  const { height, width } = useWindowDimensions();
  let [numberOfRows, setNumberOfRows] = useState(2);
  let [rowSize, setRowSize] = useState(`20vw`);

  let rotations = props.rotations;
  let setRotations = props.setRotations;

  let quizOpen = props.quizOpen;
  let setQuizOpen = props.setQuizOpen;

  let [posters, setPosters] = useState(Array(0).fill(null))
  let textures = [PosterTexture1, PosterTexture2, PosterTexture3, PosterTexture4, PosterTexture5];
  let [textureIndexes, setTextureIndexes] = useState([])

  const getCurrentEvents = async () => {
    let posterObjs = []
    const q = await query(collection(db, "posters"), orderBy("expiry"));
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      let poster = posterConverter.fromFirestore(doc);
      let date = Date.parse(poster.expiry)
      let yesterday = ((new Date()).getTime());
      yesterday -= 60 * 60 * 24 * 1000
      if (date >= yesterday) {
        if (poster.eventUrl === "quiz") {
          posterObjs.unshift(poster)
        } else {
          posterObjs.push(poster)
        }
      }
    });
    setPosters(posterObjs);
  }

  useEffect(() => {
    setRotations(rotations);
  }, [rotations]);

  useEffect(() => {
    setRotations(setRndRotations());
    getCurrentEvents();
  }, []);

  useEffect(() => {
    if (textureIndexes.length < 1) {
      let indexes = []
      for (let i = 0; i < posters.length; i++) {
        let rnd = randomIntFromInterval(0, 4);
        indexes.push(rnd);
      }
      setTextureIndexes(indexes)
    }
  }, [posters]);

  useEffect(() => {
    // setPosterUrls(Array(posters.length).fill(''))
    if (posters.length > 0) {
      setNumberOfRows(Math.ceil(posters.length / 3));
    }
  }, [posters]);

  useEffect(() => {
    if (width > 1400) {
      setRowSize(`20vw`)
    } else if (width <= 1400 && width > 640) {
      setRowSize(`30vw`)
    } else {
      setRowSize(`50vw`)
    }
  }, [numberOfRows, width]);

  return (
    <div className={'eventsContainer'} style={{gridTemplateRows: `repeat(${numberOfRows}, ${rowSize})`}}>
      {posters.map((poster, index) => (
        <div onClick={() => {
          if (poster.eventUrl === "quiz") {
            setQuizOpen(true)
          } else if (poster.eventUrl !== 'test' && poster.eventUrl !== '') {
            window.open(poster.eventUrl, "_blank")
          }
        }} key={index} className="posterContainer" style={{transform: `rotate(${rotations[index]}deg)`}}>
          <img className={'texture'} style={{backgroundImage: `url('${textures[textureIndexes[index]]}')`}}/>
          <img style={{backgroundImage: `url('${poster.imageUrl}')`}}/>
        </div>
      ))}
    </div>
  )
}

export default Events