import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {collection, doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import {beerConverter} from "../../../objects/Beer";
import {hourConverter as hoursConverter, hourConverter, Hours} from "../../../objects/Hours";

const UpdateHours = (props) => {
    const navigate = useNavigate();

    // Props
    let user = props.user;
    let setUser = props.setUser;

    // Firebase
    let auth = getAuth();
    let [startUpload, setStartUpload] = useState(false);

    const [monday, setMonday] = useState('Closed');
    const [tuesday, setTuesday] = useState('Closed');
    const [wednesday, setWednesday] = useState('3pm-11pm');
    const [thursday, setThursday] = useState('3pm-11pm');
    const [friday, setFriday] = useState('3pm-11pm');
    const [saturday, setSaturday] = useState('1pm-11pm');
    const [sunday, setSunday] = useState('3pm-10pm');
    const [lastOrders, setLastOrders] = useState('30 mins before close');

    const getCurrentHours = async () => {
        const docHours = await getDoc(doc(db, "openingHours", "hours"));
        let hours = hoursConverter.fromFirestore(docHours)

        setMonday(hours.monday);
        setTuesday(hours.tuesday);
        setWednesday(hours.wednesday);
        setThursday(hours.thursday);
        setFriday(hours.friday);
        setSaturday(hours.saturday);
        setSunday(hours.sunday);
        setLastOrders(hours.lastOrders);
    }

    const uploadToFirestore = async () => {
        // let hours = new Hours(monday, tuesday, wednesday, thursday, friday, saturday, sunday, lastOrders);
        const ref = doc(db, "openingHours", "hours")
        await updateDoc(ref, {
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            lastOrders: lastOrders
        })
    }

    // Start uploading if SAVE is clicked
    useEffect(() => {
        if (startUpload) {
            uploadToFirestore().then(() => {
                alert("Updated hours")
                window.location.reload();
            })
        }
    }, [startUpload]);

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
        } else {
            navigate('/admin')
        }
    });

    useEffect(() => {
        getCurrentHours()
    }, [])

    return (
      <>
          {user === null ? <div></div> : <div>
              <h1 className={'adminHeader'}>UPDATE HOURS</h1>
              <div>
                  <div className={'hourText'}><b>Mon</b> <input onChange={(event) => {
                      setMonday(event.target.value)
                  }} value={monday}></input></div>
                  <div className={'hourText'}><b>Tues</b> <input onChange={(event) => {
                      setTuesday(event.target.value)
                  }} value={tuesday}></input></div>
                  <div className={'hourText'}><b>Wed</b> <input onChange={(event) => {
                      setWednesday(event.target.value)
                  }} value={wednesday}></input></div>
                  <div className={'hourText'}><b>Thurs</b> <input onChange={(event) => {
                      setThursday(event.target.value)
                  }} value={thursday}></input></div>
                  <div className={'hourText'}><b>Fri</b> <input onChange={(event) => {
                      setFriday(event.target.value)
                  }} value={friday}></input></div>
                  <div className={'hourText'}><b>Sat:</b> <input onChange={(event) => {
                      setSaturday(event.target.value)
                  }} value={saturday}></input></div>
                  <div className={'hourText'}><b>Sun:</b> <input onChange={(event) => {
                      setSunday(event.target.value)
                  }} value={sunday}></input></div>
                  <div><b>Last Orders:</b> <input onChange={(event) => {
                      setLastOrders(event.target.value)
                  }} value={lastOrders}></input></div>
              </div>
              <button onClick={() => {
                  setStartUpload(true)
              }}>SAVE
              </button>
          </div>}
      </>
    )
}

export default UpdateHours;