// Stylesheets
import './updateBeers.css';
import './updateBeersMobile.css';

// Components
import ColourPicker from "../../../components/colourPicker/ColourPicker";
import Beer, {beerConverter} from "../../../objects/Beer";

// React
import {useEffect, useState} from "react";

// Firebase
import {collection, doc, getDocs, setDoc, deleteDoc, orderBy, query} from "firebase/firestore";
import {db} from "../../../firebase";
import {useNavigate} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {posterConverter} from "../../../objects/Poster";
import {Eyedropper, Reset, Upload} from "../../../imgs";
import Compressor from 'compressorjs'

const UpdateBeers = (props) => {
  const navigate = useNavigate();

  // Props
  let user = props.user;
  let setUser = props.setUser;

  // Firebase
  let auth = getAuth();
  const storage = getStorage();
  let [startUpload, setStartUpload] = useState(false);
  let [beerImagesUploading, setBeerImagesUploading] = useState([]);
  let [progress, setProgress] = useState(0);

  // Beer info
  let [beerName, setBeerName] = useState('');
  let [beers, setBeers] = useState([]); // All beers related to search
  let [selectedBeers, setSelectedBeers] = useState([]);

  // Colour Picker
  let [pickerOpen, setPickerOpen] = useState(false);
  let [colourIndex, setColourIndex] = useState(0);
  let [currentItemColour, setCurrentItemColour] = useState('')

  const getCurrentBeers = async () => {
    let newBeers = []
    const q = await query(collection(db, "beersOnTap"), orderBy("position"));
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      let beer = beerConverter.fromFirestore(doc);
      newBeers.push(beer)
    });
    setSelectedBeers(newBeers);
  }

  function getBeers() {
    fetch(`https://business.untappd.com//api/v1/items/search?q=${beerName.replace(' ', '-')}`, {
      headers: {
        Authorization: "Basic aXNhYWNAbW9zYWljdGFwLmNvbTpVTi1DRUMyTG9vVFBHdHF6NjFaVw==",
        "Content-Type": "application/json"
      },
      method: "GET"
    }).then(r => {
      return r.json()
    }).then(json => {
      if (json.items.length > 0) {
        console.log(json.items)
        setBeers(json.items)
      }
    })
  }

  // For removing from beer selection array
  const handleDelete = (index) => {
    const newArray = selectedBeers.filter((item, i) => i !== index);
    setSelectedBeers(newArray);
  };

  const updatePosition = (index, position) => {
    let newBeerArr = []
    for (let i = 0; i < selectedBeers.length; i++) {
      let newBeer = selectedBeers[i]
      if (i === index) {
        newBeer.position = position === '' ? '' : parseInt(position)
        newBeerArr.push(newBeer)
      } else {
        newBeerArr.push(selectedBeers[i])
      }
    }
    setSelectedBeers(newBeerArr)
  }

  const updateUrl = (index) => {
    let newBeerArr = []
    for (let i = 0; i < selectedBeers.length; i++) {
      let newBeer = selectedBeers[i]
      if (i === index) {
        newBeer.imageUrl = `https://labels.untappd.com/${newBeer.pageUrl.split("/").pop()}?size=hd`
        newBeerArr.push(newBeer)
      } else {
        newBeerArr.push(selectedBeers[i])
      }
    }
    setSelectedBeers(newBeerArr)
  }

  const uploadToStorage = async () => {
    let imagesUploading = [];

    for (let i in selectedBeers) {
      let currentProgress = (parseInt(i)) / selectedBeers.length
      setProgress(currentProgress * 100)

      let beerObj = selectedBeers[i]
      if (beerObj.imageUrl === '') {
        continue
      }

      if (beerObj.imageUrl instanceof File) {
        getDownloadURL(ref(storage, `Beer Images/${beerObj.name.replaceAll("/", "-")}`)).then(onResolve, onReject);

        function onResolve(foundURL) {
          //stuff
          console.log("found")
        }

        function onReject(error) {
          console.log("not found")
          console.log(error.code);
        }

        let storageRef = ref(storage, `Beer Images/${beerObj.name.replaceAll("/", "-")}`);
        const uploadTask = uploadBytesResumable(storageRef, beerObj.imageUrl);

        uploadTask.on('state_changed',
          (snapshot) => {
            if (!imagesUploading.includes(beerObj.name)) {
              imagesUploading.push(beerObj.name)
            }

            // setBeerImagesUploading(beerImagesUploading => [...beerImagesUploading, beerObj]);

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            setProgress(progress)
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            console.log("checking", imagesUploading)
            console.log("Uploaded custom image for", beerObj.name)
            const index = imagesUploading.indexOf(beerObj.name);
            if (index > -1) { // only splice array when item is found
              imagesUploading.splice(index, 1); // 2nd parameter means remove one item only
            }
            console.log("complete finished", imagesUploading)
            // setBeerImagesUploading(imagesUploading);
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              console.log('File available at', downloadURL);
              beerObj.imageUrl = downloadURL
              await uploadToFirestore(beerObj)
            });
          });
      } else {
        await uploadToFirestore(beerObj)
      }
    }
    // console.log("finished", imagesUploading)
    // setBeerImagesUploading(imagesUploading);
    if (imagesUploading.length <= 0) {
      successfulUpload();
    }
  }

  const successfulUpload = () => {
    setTimeout(() => {
      alert('All beers saved successfully')
      setStartUpload(false)
      window.location.reload()
    }, 1000)
  }

  const uploadToFirestore = async (beer) => {
    const ref = doc(db, "beersOnTap", beer.name.replaceAll("/", "-"))
        .withConverter(beerConverter)
    await setDoc(ref, beer).then(() => {
      handleDelete(0)
    })
  }

  // Remove all beers before adding
  const deleteCurrentDB = async () => {
    let ids = []
    const querySnapshot = await getDocs(collection(db, "beersOnTap"));
    querySnapshot.forEach((doc) => {
      ids.push(doc.id)
    });

    for (let i = 0; i < ids.length; i++) {
      await deleteDoc(doc(db, "beersOnTap", ids[i]));
    }
  }

  // Start uploading if SAVE is clicked
  useEffect(() => {
    if (startUpload) {
      if (selectedBeers.length !== 10) {
        alert("You must have exactly 10 beers selected")
        setStartUpload(false)
      } else {
        deleteCurrentDB().then(r => uploadToStorage())
      }
    }
  }, [startUpload]);

  // Get current list on page load
  useEffect(() => {
    getCurrentBeers()
  }, []);

  const getColour = (imgUrl, style) => {
    let colour = 'gold'
    if (style.toLowerCase().includes('ipa')) {
      colour = "gold"
    } else if (style.toLowerCase().includes('stout')) {
      colour = "#092215FF"
    } else if (style.toLowerCase().includes('lager')) {
      colour = "rgba(255,243,161,0.5)"
    } else if (style.toLowerCase().includes('sour')) {
      colour = 'blue'  // TODO: Make function that takes average colour from image
    }

    return colour;
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  return (
    <div>
      {user === null ? <div></div> :
        <div className={'beerAdminContainer'}>
          <div className={'loading'} style={{display: startUpload ? 'flex' : 'none'}}>SAVING... ({progress.toFixed(2)}%)</div>
          <ColourPicker selectedBeers={selectedBeers} setSelectedBeers={setSelectedBeers} index={colourIndex}
                        colour={currentItemColour} className={'colourPicker'} open={pickerOpen} setOpen={setPickerOpen}/>
          <div className={'beerSelectionContainer'}>
            <h1 className={'adminHeader'}>UPDATE BEERS</h1>
            <div>Search for a <i><b>beer</b></i>! Best practice is to search <i><b>Brewery Name + Beer Name</b></i>, e.g. Vault City Grape Soda</div>
            <div className={'beersLogoList'}>
              {beers.map((item, i) => <img key={i} onClick={() => {
                if (selectedBeers.indexOf(item) <= -1) {
                  let beer = new Beer(
                    item.name, item.description, item.brewery, item.brewery_location, item.brewery_country,
                      item.original_label_image_hd, item.abv, item.style,
                      `https://untappd.com/b/${item.untappd_beer_slug}/${item.untappd_id}`,
                    getColour(item.original_label_image_hd, item.style), selectedBeers.length + 1);
                  setSelectedBeers(selectedBeers => [...selectedBeers, beer]);
                }
              }} src={item.original_label_image_hd} alt={`${item.name} logo`}/>)}
            </div>
            <div className={'beerSelectionFooter'}>
              <input className={'adminInput'} style={{marginRight: '10px'}} onChange={(evt) => {
                setBeerName(evt.target.value);
              }}/>
              <button className={'adminBtn'} onClick={() => {
                getBeers()
              }}>SEARCH
              </button>
            </div>
          </div>
          <div className={'basketContainer'}>
            <div className={'itemsInBasket'}>
              {/*.sort((a, b) => parseInt(a.position) > parseInt(b.position) ? 1 : -1)*/}
              {selectedBeers.map((item, index) =>
                <div style={{backgroundColor: item.tapColour}} className={'beerItem'} key={index}>
                  <div>
                    <input className={'adminInput positionInput'} tabIndex={index + 1} placeholder={'Position'}
                           type={'number'} onChange={(event) => {
                      updatePosition(index, event.target.value)
                    }} value={`${item.position}`}></input>
                    <img className={'basketBeerImg'} onClick={() => {
                      handleDelete(index)
                    }} src={item.imageUrl instanceof File ? URL.createObjectURL(item.imageUrl) : item.imageUrl}
                         alt={`${item.name} logo`}/>
                    <div>{item.name}</div>
                  </div>
                  <div className={'beerBtnContainer'}>
                    <button className={'adminBtn beerButton'} onClick={() => {
                      setCurrentItemColour(item.tapColour)
                      setColourIndex(index)
                      setPickerOpen(true)
                    }}><img className={'adminBeerBtnImg'} src={Eyedropper}/></button>
                    <div>
                      <label htmlFor={`beerImgUpload${index}`} className={'adminBtn beerButton'}><img
                        className={'adminBeerBtnImg'} src={Upload} /></label>
                      <input
                        type="file"
                        id={`beerImgUpload${index}`}
                        style={{display: 'none'}}
                        onChange={(event) => {
                          let file = event.target.files[0];
                          console.log(file)

                          new Compressor(file, {
                            retainExif: false,
                            maxHeight: 1000,
                            maxWidth: 1000,
                            quality: 0.25,
                            convertSize: 1000000,

                            success(result) {
                              let compressedFile = null;
                              if (result instanceof File) compressedFile = result;
                              else {
                                compressedFile = new File([result], result.name, {
                                  type: result.type,
                                });
                              }

                              let tempBeers = []
                              for (let i = 0; i < selectedBeers.length; i++) {
                                if (index === i) {
                                  let tempBeer = selectedBeers[i];
                                  tempBeer.imageUrl = compressedFile
                                  tempBeers.push(tempBeer)
                                } else {
                                  tempBeers.push(selectedBeers[i])
                                }
                              }
                              setSelectedBeers(tempBeers);
                            }
                          })
                        }}
                      />
                    </div>
                    <button onClick={() => {
                      updateUrl(index)
                    }} className={'adminBtn beerButton'}><img className={'adminBeerBtnImg'} src={Reset}/></button>
                  </div>
                </div>
              )}
            </div>
            <div className={'bottomOfBasket'}>
              <div>
                {`${selectedBeers.length} beers selected`}
              </div>
              <button className={'adminBtn saveBeerBtn'} onClick={() => {
                setStartUpload(true)
              }}>
                SAVE
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UpdateBeers;