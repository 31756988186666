import './reviews.css';
import './reviewsMobile.css';
import './reviewsTablet.css';

import {useEffect, useRef, useState} from "react";
import ReactStars from "react-stars/dist/react-stars";
import useWindowDimensions from "../../func/getWindowDimensions";

const Reviews = (props) => {
  const { height, width } = useWindowDimensions();
  let [translate, setTranslate] = useState(0);

  const delay = 6000;
  const timeoutRef = useRef(null);
  const [index, setIndex] = useState(0);
  let [reviews, setReviews] = useState([])

  let bannedReviews = ['What a cool place, literally, it was cold.  The jackets never came off.  The beer and beer selection was on point for such a small place.  Also a nice selection of beers to go.  Also very convenient as it was just down the street from our hotel.',
    "First time here but sadly it was the last bar of the day and my memory is a little rusty. I remember the beer selection being good and the pint I had was very nice, just don't ask me what it was! Will go back again if only to check it out with a clearer head."]

  const getReviews = () => {
    fetch(`https://places.googleapis.com/v1/places/ChIJXaFxEAFxfkgR0G7q7KQNVd8?fields=id,displayName,reviews&key=AIzaSyA7UjW4zL9uo0SiaD01FBKjZV22Cg4GY0M`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "GET"
    }).then(r => {
      return r.json()
    }).then(json => {
      if (json.reviews !== undefined && json.reviews.length > 0) {
        let newReviews = []
        for (let i = 0; i < json.reviews.length; i++) {
          if (!bannedReviews.includes(json.reviews[i].originalText.text)) {
            newReviews.push(json.reviews[i])
          }
        }
        setReviews(newReviews)
      }
    })
  }

  useEffect(() => {
    if (reviews.length < 5) {
      getReviews()
    }
  }, []);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    setTranslate((width > 640) ? `calc((35vw - 70px) + ${(-index * 35)}vw + ${(-index * 70)}px)` : `calc(${-index * 100}% - ${index * 2}px)`)
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => {
        setIndex((prevIndex) =>
          prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
        )
      },
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

// the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 10

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => {
    let move = e.targetTouches[0].clientX
    const distance = touchStart - move
    setTouchEnd(e.targetTouches[0].clientX)
    setTranslate((width > 640) ? `calc((35vw - 70px) + ${(-index * 35)}vw + ${(-index * 70)}px - ${distance}px)` : `calc(${-index * 100}% - ${index * 2}px - ${distance}px)`)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      setIndex((prevIndex) =>
        prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
      )
    } else {
      setIndex((prevIndex) =>
        prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
      )
    }
  }

  return (
    <div className={"reviewOuterContainer"}>
      <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className={'reviewContainer'} style={{ transform: `translate3d(${translate}, 0, 0)` }}>
        {reviews.map((review, i) => (
          <div className={'reviewCard'} key={i}>
            <div className={'reviewHeader'}>
              <img onClick={() => {
                window.open(review.authorAttribution.uri, "_blank")
              }} className={'reviewProfilePic'} src={review.authorAttribution.photoUri}/>
              <div className={'reviewProfileInfo'}>
                <div className={'reviewText reviewProfileName'}>{review.authorAttribution.displayName}</div>
                <div className={'reviewText reviewPublishTime'}>{review.relativePublishTimeDescription}</div>
              </div>
            </div>
            <div className={'reviewFooter'}>
              <ReactStars className={'reviewStars'} count={5} value={review.rating} edit={false} size={24}
                          color2={'#ffd700'}/>
              <div className={'reviewText text'}>{review.originalText.text}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="slideshowDots">
        {reviews.map((_, idx) => (
          <div key={idx} className={`slideshowDot${index === idx ? " active" : ""}`} onClick={() => {
            setIndex(idx);
          }}></div>
        ))}
      </div>
    </div>
  )
}

export default Reviews;