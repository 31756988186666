import {A, B, E1, E2, N, O, P, R, T, TekuGlass} from "../../../../imgs";
import Liquid from "../../liquid/Liquid";
import BeerLogoTile from "../../beerLogoTile/BeerLogoTile";
import Tap from "../../tap/Tap";
import NameTile from "../../nameTile/NameTile";
import {useEffect, useState} from "react";
import poorRandomBeer from "../funcs/pourRandomBeer";
import Teku from "../../teku/Teku";

const DesktopWall = (props) => {
    let setBeerCard = props.setBeerCard;

    let beers = props.beers;
    let [tapsOn, setTapsOn] = useState(Array(10).fill(false));

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         poorRandomBeer(tapsOn, setTapsOn)
    //     }, 10000);
    //
    //     return () => clearInterval(interval);
    // }, []);

    return (
        <>
            <div className="grid-container">
                <Teku tapsOn={tapsOn} />
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={B} alt={'Handwritten letter B'}/>
                </div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={E1} alt={'Handwritten letter E'}/>
                </div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={E2} alt={'Handwritten letter E'}/>
                </div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={R} alt={'Handwritten letter R'}/>
                </div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={O} alt={'Handwritten letter O'}/>
                </div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={N} alt={'Handwritten letter O'}/>
                </div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}>
                    <img onClick={() => {

                    }} className={'beerOnTapLetter'} src={T} alt={'Handwritten letter T'}/>
                </div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={A} alt={'Handwritten letter A'}/>
                </div>
                <div className={'grid-item'}>
                    <img className={'beerOnTapLetter'} src={P} alt={'Handwritten letter P'}/>
                </div>
                {/* Row 2 */}
                <Liquid tapOn={tapsOn[0]} beer={beers[0]} row={1}/>
                <div className={'grid-item'}></div>
                <Liquid tapOn={tapsOn[1]} beer={beers[1]} row={1}/>
                <div className={'grid-item'}></div>
                <Liquid tapOn={tapsOn[2]} beer={beers[2]} row={1}/>
                <div className={'grid-item'}></div>
                <Liquid tapOn={tapsOn[3]} beer={beers[3]} row={1}/>
                <div className={'grid-item'}></div>
                <Liquid tapOn={tapsOn[4]} beer={beers[4]} row={1}/>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                {/* Row 3 */}
                <BeerLogoTile beer={beers[0]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={0}/>
                <BeerLogoTile beer={beers[1]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={1}/>
                <BeerLogoTile beer={beers[2]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={2}/>
                <BeerLogoTile beer={beers[3]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={3}/>
                <BeerLogoTile beer={beers[4]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={4}/>
                <div className={'grid-item'}></div>
                {/* Row 4 */}
                <NameTile beer={beers[0]}/>
                <Liquid tapOn={tapsOn[5]} beer={beers[5]} row={2}/>
                <NameTile beer={beers[1]}/>
                <Liquid tapOn={tapsOn[6]} beer={beers[6]} row={2}/>
                <NameTile beer={beers[2]}/>
                <Liquid tapOn={tapsOn[7]} beer={beers[7]} row={2}/>
                <NameTile beer={beers[3]}/>
                <Liquid tapOn={tapsOn[8]} beer={beers[8]} row={2}/>
                <NameTile beer={beers[4]}/>
                <Liquid tapOn={tapsOn[9]} beer={beers[9]} row={2}/>
                {/* Row 5 */}
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <BeerLogoTile beer={beers[5]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={5}/>
                <BeerLogoTile beer={beers[6]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={6}/>
                <BeerLogoTile beer={beers[7]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={7}/>
                <BeerLogoTile beer={beers[8]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={8}/>
                <BeerLogoTile beer={beers[9]} setBeerCard={setBeerCard}/>
                <Tap tapsOn={tapsOn} setTapsOn={setTapsOn} beers={beers} index={9}/>
                {/* Row 6 */}
                <div className={'grid-item'}></div>
                <NameTile beer={beers[5]}/>
                <div className={'grid-item'}></div>
                <NameTile beer={beers[6]}/>
                <div className={'grid-item'}></div>
                <NameTile beer={beers[7]}/>
                <div className={'grid-item'}></div>
                <NameTile beer={beers[8]}/>
                <div className={'grid-item'}></div>
                <NameTile beer={beers[9]}/>
                <div className={'grid-item'}></div>
                {/* Row 7*/}
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
                <div className={'grid-item'}></div>
            </div>
        </>
    )
}

export default DesktopWall;