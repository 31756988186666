import './heroImages.css';
import './heroImagesMobile.css';
import './heroImagesTablet.css';

import {useEffect, useRef, useState} from "react";
import {listAll, getStorage, ref, getDownloadURL} from "firebase/storage";
import useWindowDimensions from "../../func/getWindowDimensions";

const HeroImages = (props) => {
    const { height, width } = useWindowDimensions();

    const storage = getStorage();
    const imagesRef = ref(storage, 'Hero');

    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);
    const delay = 3675;

    const [images, setImages] = useState([])
    const [gotImages, setGotImages] = useState(false)
    const [translate, setTranslate] = useState(`translate3d(calc(${-index * 25}vw + (${-index * 50}px - 25px) + 36vw), 0, 0)`);

    useEffect(() => {
        // setImages(images.filter(function(item, pos, self) {
        //     return self.indexOf(item) === pos;
        // }))
    }, [images]);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    const getCurrentImages = async () => {
        listAll(imagesRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                });
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                    getDownloadURL(itemRef).then((url) => {
                        setImages(images => [...images, url]);
                    })

                });
            }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    useEffect(() => {
        if (images.length <= 0) {
            setGotImages(true)
        }
    }, []);

    useEffect(() => {
        if (gotImages) {
            setGotImages(false)
            getCurrentImages()
        }
    }, [gotImages]);

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

// the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 10

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => {
        let move = e.targetTouches[0].clientX
        const distance = touchStart - move
        setTouchEnd(e.targetTouches[0].clientX)
        // setTranslate((width > 640) ? `calc((35vw - 70px) + ${(-index * 35)}vw + ${(-index * 70)}px - ${distance}px)` : `calc(${-index * 100}% - ${index * 2}px - ${distance}px)`)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        if (isLeftSwipe) {
            setIndex((prevIndex) =>
              prevIndex === images.length - 1 ? 0 : prevIndex + 1
            )
        } else {
            setIndex((prevIndex) =>
              prevIndex === 0 ? images.length - 1 : prevIndex - 1
            )
        }
    }

    useEffect(() => {
        if (width > 1400) {
            setTranslate(`translate3d(calc(${-index * 25}vw + (${-index * 50}px - 25px) + 36vw), 0, 0)`)
        } else if (width <= 1400 && width > 640) {
            setTranslate(`translate3d(calc(${-index * 40}vw + (${-index * 50}px - 25px) + 30vw), 0, 0)`)
        } else {
            setTranslate(`translate3d(calc(${-index * 100}vw), 0, 0)`)
        }
    }, [width, index]);

    return (
        <div className="slideshow">
            <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className="slideshowSlider" style={{ transform: translate }}>
                {images.map((url, index) => (
                    <img className="slide" key={index} src={url}/>
                ))}
            </div>

            <div className="slideshowDots">
                {images.map((_, idx) => (
                    <div key={idx} className={`slideshowDot${index === idx ? " active" : ""}`} onClick={() => {
                        setIndex(idx);
                    }}></div>
                ))}
            </div>
        </div>
    )
}

export default HeroImages;