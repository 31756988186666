import './nameTile.css';
import './nameTileMobile.css';
import './nameTileTablet.css';

import {randomIntFromInterval} from "../../../func/randomNumber";
import {useEffect, useRef, useState} from "react";
import useWindowDimensions from "../../../func/getWindowDimensions";

const NameTile = (props) => {
  const { height, width } = useWindowDimensions();

  let beer = props.beer
  let colours = ['#08229e', '#44bc19', '#d4282d', '#d761b8']
  let [nameColour, setNameColour] = useState('')
  let [infoColour, setInfoColour] = useState('')

  let nameRef = useRef(null)
  let [fontSize, setFontSize] = useState('1.4vmin')

  let style = beer.style
  if (style.includes('-')) {
    style = style.split('-')[0]
  } else if (style.includes('/')) {
    style = style.split('/')[0]
  }

  useEffect(() => {
    if (nameColour === '') {
      setNameColour(colours[randomIntFromInterval(0, 3)])
    }
    if (infoColour === '') {
     setInfoColour(colours[randomIntFromInterval(0, 3)])
    }
  }, []);

  useEffect(() => {
    let name = nameRef.current
    let divHeight = name.offsetHeight
    let lineHeight = parseInt(getComputedStyle(name).lineHeight);
    let lines = divHeight / lineHeight;

    if (lines > 4) {
      if (width >= 1400) {
        setFontSize('1.2vmin')
      } else if (width < 1400 && width > 640) {
        setFontSize('1.3vw')
      } else {
        setFontSize('1.8vw')
      }
    } else {
      if (width >= 1400) {
        setFontSize('1.4vmin')
      } else if (width < 1400 && width > 640) {
        setFontSize('1.6vw')
      } else {
        setFontSize('2.2vw')
      }
    }
  }, [beer])

  return (
      <div className={'grid-item'}>
        <div style={{color: nameColour}} className={'beerGridName'}>
          <div ref={nameRef} style={{fontSize: fontSize}} className={'beerName'}>
            {beer.name}
          </div>
          <div className={'beerNameDivider'}></div>
          <div className={'beerBrewery'}>
            {beer.brewery}
          </div>
          <div className={'beerNameDivider'}></div>
          <div style={{color: infoColour}} className={'beerInfo'}>
            <div className={'beerAbv'}>
              {beer.abv}%
            </div>
            <div className={'beerStyle'}>
              {style}
            </div>
          </div>

        </div>
      </div>
  )
}

export default NameTile;