import './updateHeroImages.css';

import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {getDownloadURL, getStorage, listAll, ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import {useEffect, useState} from "react";
import Compressor from "compressorjs";

const UpdateHeroImages = (props) => {
    const navigate = useNavigate();

    let [firebaseRefs, setFirebaseRefs] = useState([])
    let [newHeroObjs, setNewHeroObjs] = useState([]);

    // Props
    let user = props.user;
    let setUser = props.setUser;

    // Firebase
    let auth = getAuth();
    const storage = getStorage();
    let [startUpload, setStartUpload] = useState(false);

    const uploadToStorage = async () => {
        for (let j = 0; j < firebaseRefs.length; j++) {
            let oldImageName = firebaseRefs[j].split("/").pop()

            for (let i = 0; i < newHeroObjs.length; i++) {
                let currentImageName = newHeroObjs[i].split("Hero%2F").pop()
                currentImageName = currentImageName.split("?")[0].replace("%20", " ")

                // console.log(oldImageName, currentImageName)

                if (oldImageName === currentImageName) {
                    break;
                } else if (i === newHeroObjs.length - 1) {
                    console.log(oldImageName)
                    console.log("delete")

                    const deleteRef = ref(storage, firebaseRefs[j]);
                    deleteObject(deleteRef).then(() => {
                        // File deleted successfully
                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });
                }
            }
        }

        for (let i = 0; i < newHeroObjs.length; i++) {
            let heroObj = newHeroObjs[i]
            if (heroObj === '') {
                continue
            }

            if (heroObj instanceof File) {
                let storageRef = ref(storage, `Hero/${heroObj.name}`);
                const uploadTask = uploadBytesResumable(storageRef, heroObj);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // Handle unsuccessful uploads
                    },
                    () => {
                        if (i === newHeroObjs.length - 2) {
                            alert("Successfully updated hero images")
                            setStartUpload(false)
                            // window.location.reload()
                        }
                    });
            } else {
                if (i === newHeroObjs.length - 2) {
                    alert("Successfully updated hero images")
                    setStartUpload(false)
                    // window.location.reload()
                }
            }
        }
    }

    const getCurrentHero = async () => {
        const listRef = ref(storage, 'Hero');
        listAll(listRef)
            .then((res) => {
                let refs = []
                res.items.forEach((itemRef) => {
                    refs.push(itemRef.fullPath)
                });
                return refs;
            }).then((refs) => {
                setFirebaseRefs(refs)
            })
            .catch((error) => {
            // Uh-oh, an error occurred!
            })
    }

    useEffect(() => {
        for (let i = 0; i < firebaseRefs.length; i++) {
            let itemRef = firebaseRefs[i];
            getDownloadURL(ref(storage, itemRef))
                .then((url) => {
                    // `url` is the download URL for 'images/stars.jpg'

                    // This can be downloaded directly:
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                    };
                    xhr.open('GET', url);
                    xhr.send();

                    return url
                }).then((url) => {
                    if (!newHeroObjs.includes(url)) {
                        setNewHeroObjs(newHeroObjs => [...newHeroObjs, url]);
                    }
                })
                .catch((error) => {
                    // Handle any errors
                });
        }
    }, [firebaseRefs]);

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
        } else {
            navigate('/admin')
        }
    });

    useEffect(() => {
        if (newHeroObjs.length >= firebaseRefs.length && newHeroObjs.length > 0 && newHeroObjs[newHeroObjs.length - 1] !== '') {
            setNewHeroObjs(newHeroObjs => [...newHeroObjs, '']);
        }
    }, [newHeroObjs]);

    useEffect(() => {
        if (firebaseRefs.length <= 0) {
            getCurrentHero()
        }
    }, []);

    useEffect(() => {
        if (startUpload) {
            uploadToStorage()
        }
    }, [startUpload]);

    return (
      <>
          {user === null ? <div></div> : <div>
              <h1 className={'adminHeader'}>UPDATE HERO IMAGES</h1>
               <div>
                  <div className={'eventsContainer updateEventsContainer outerHeroImagesContainer'}>
                      {newHeroObjs.map((poster, index) => (
                        <div key={index} className="updatePosterContainer heroImageContainer">
                            <img onClick={() => {
                                // Remove selected poster
                                let newHeros = []
                                for (let i = 0; i < newHeroObjs.length; i++) {
                                    if (i !== index) {
                                        newHeros.push(newHeroObjs[i])
                                    }
                                }
                                setNewHeroObjs(newHeros)
                            }} src={newHeroObjs.length > index && newHeroObjs[index] instanceof File ?
                              URL.createObjectURL(newHeroObjs[index]) : newHeroObjs[index]}/>
                            <div>
                                <div>
                                    <input
                                      type="file"
                                      name="myImage"
                                      onChange={(event) => {
                                          let file = event.target.files[0];

                                          new Compressor(file, {
                                              retainExif: true,
                                              quality: 0.45,
                                              convertSize: 1000000,

                                              success(result) {
                                                  let compressedFile = null;
                                                  if (result instanceof File) compressedFile = result;
                                                  else {
                                                      compressedFile = new File([result], result.name, {
                                                          type: result.type,
                                                      });
                                                  }

                                                  if (newHeroObjs[0] === '') { // If currently empty
                                                      setNewHeroObjs([compressedFile])
                                                  } else {
                                                      let tempHero = []
                                                      for (let i = 0; i < newHeroObjs.length; i++) {
                                                          if (index === i) {
                                                              tempHero.push(compressedFile)
                                                          } else {
                                                              tempHero.push(newHeroObjs[i])
                                                          }
                                                      }
                                                      setNewHeroObjs(tempHero);
                                                  }
                                              }
                                          })
                                      }}
                                    />
                                </div>
                            </div>
                        </div>
                      ))}
                  </div>
                  <div>
                      <button className={'adminBtn'} onClick={() => {
                          setStartUpload(true)
                      }}>SAVE
                      </button>
                  </div>
              </div>
          </div>}
      </>
    )
}

export default UpdateHeroImages;