import './teku.css';
import {useEffect, useRef, useState} from "react";

const Teku = (props) => {
    let tapsOn = props.tapsOn;

    const [position, setPosition] = useState({ x: "93%", y: "83%" });
    const [isFollowing, setIsFollowing] = useState(false);
    const [isColliding, setIsColliding] = useState(false);
    const [collidingElements, setCollidingElements] = useState(new Set()); // Use a Set to store colliding elements
    const tekuRef = useRef(null);

    let [height, setHeight] = useState(55)
    let [rotate, setRotate] = useState(0)
    const [beerColour, setBeerColour] = useState("#fff200")
    let [clicked, setClicked] = useState(false);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (isFollowing) {
                let tekuCurrent = tekuRef.current.getBoundingClientRect();
                setPosition({ x: e.clientX - (tekuCurrent.width / 2), y: e.clientY - (tekuCurrent.height / 2) });
                checkCollision();
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isFollowing]);

    const handleMouseUp = () => {
        setIsFollowing(false);
        document.body.style.cursor = 'default';
    };

    const handleMouseDown = () => {
        setClicked(true)
        setIsFollowing(true);
        document.body.style.cursor = 'none';
    };

    const checkCollision = () => {
        if (tekuRef) {
            const tekuRect = tekuRef.current.getBoundingClientRect();
            const liquid = document.querySelectorAll('.beerPourLiquidRow1, .beerPourLiquidRow2')

            const newCollidingElements = new Set();

            liquid.forEach(element => {
                const beerPourRect = element.getBoundingClientRect();

                const isColliding =
                  tekuRect.left < beerPourRect.right &&
                  tekuRect.right > beerPourRect.left &&
                  tekuRect.top < beerPourRect.bottom &&
                  tekuRect.bottom > beerPourRect.top;


                if (isColliding) {
                    setBeerColour(element.style.backgroundColor);
                    newCollidingElements.add(element);
                }
            });
            setCollidingElements(newCollidingElements);
        }
    };

    useEffect(() => {
        if (collidingElements.size === 0) {
            setIsColliding(false);
        } else if (collidingElements.size > 0 ) {
            setIsColliding(true);
        }
    }, [collidingElements]);

    useEffect(() => {
        if (isColliding) {
            if (height > 0) {
                setRotate(-15)
                setHeight(0)
            }
        } else {
            setTimeout(() => {
                if (height < 55) {
                    setRotate(0)
                    setHeight(55)
                }
            }, 500)
        }
    }, [height, isColliding]);

    // Check collision if taps are updated
    useEffect(() => {
        setTimeout(() => {
            checkCollision()
        }, 1250)
    }, [tapsOn]);

    return (
        <>
            <div className={'dragMeLabel'} style={{display: clicked ? 'none' : 'block'}}>
                DRAG ME!
            </div>
            <svg className={'teku'} onMouseDown={handleMouseDown} style={{left: position.x, top: position.y,
                transform: `rotate(${rotate}deg`, cursor: isFollowing ? 'none' : ''}}
                 id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225.37 483.29">
                <defs>
                    <mask id="mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                        <rect id={`filling-rect`} x="0" y="0" width="100%"
                              height={`${height}%`} fill="black" ref={tekuRef}/>
                    </mask>
                </defs>
                <g id="Layer_1-2">
                    <g className="cls-2">
                        <path className="cls-3" style={{fill: beerColour}}
                              d="m149.2,252.86c0-6.98-33.06-6.83-36.51-6.83s-36.71-.47-36.71,6.74c-61.96-27.27-61.65-36.46-64.14-42.96s3.5-84.16,16.63-109.26S52.84,30.17,52.84,30.17c0-3.31,119.7-2.82,119.7.5,0,0,5.85,34.48,18.98,59.58s21.99,113.26,22.01,120.17c.02,7.96-2.54,15.23-64.33,42.44Z"
                              mask="url(#mask)"/>
                    </g>
                    <g>
                        <path className="cls-4"
                              d="m112.53,14.93c20.43.09,44.09.78,57.97,1.85-1.46,15.06-.87,39.02,11.37,64.21,6.84,14.06,14.39,39.58,20.72,70.02,6.13,29.48,8.09,48.99,7.85,53.85-.34,1.07-.59,2.05-.81,2.88-.75,2.86-1.29,4.92-7.9,10.34-9.73,7.97-27.75,18.41-53.65,31.06-3.71-2.22-8.62-4.1-15.08-5.33-8.74-1.67-17.76-1.79-20.31-1.79-17.39,0-28.57,2.84-35.59,7.04-25.83-12.62-43.8-23.02-53.5-30.96-6.58-5.39-7.11-7.43-7.83-10.26-.22-.86-.48-1.87-.83-2.97-.23-4.86,1.72-24.37,7.85-53.86,6.33-30.44,13.88-55.96,20.72-70.02,12.24-25.17,12.72-49.12,11.16-64.22,13.89-1.06,37.49-1.75,57.86-1.84m0-14.93S37.44.33,37.44,5.1c0,0,10.21,33.22-7.36,69.36C12.51,110.61-2.89,199.29.46,208.66c3.34,9.35-2.23,19.64,80.65,58.9,0-10.38,26.87-10.6,31.57-10.6s31.57.55,31.57,10.6c82.65-39.17,77.32-49.56,80.65-58.9,3.35-9.37-12.05-98.06-29.62-134.2-17.57-36.14-7.68-69.36-7.68-69.36C187.62.33,112.53,0,112.53,0h0Z"/>
                        <path className="cls-1"
                              d="m142.28,259.16c0-9.43-25.27-9.94-29.6-9.94s-29.6.21-29.6,9.94c2.52,3.32,15.96,24.4,18.51,100.27,2.82,84.08-26.67,88.47-26.67,88.47,0,0-47.37.16-52.08,11.45-4.71,11.29,43.45,14.9,43.45,14.9,0,0,35.68,2.04,46.23,2.04h.3c10.55,0,46.23-2.04,46.23-2.04,0,0,48.16-3.61,43.45-14.9s-52.08-11.45-52.08-11.45c0,0-29.49-4.39-26.67-88.47,2.55-75.97,16.02-96.99,18.51-100.27Z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Teku;