import {randomIntFromInterval} from "./randomNumber";

const setRndRotations = () => {
  let newRotations = []
  for (let i = 0; i < 6; i++) {
    let rnd = 0
    while ((rnd > -5 && rnd < 5) || rnd === newRotations[i - 1]) {
      rnd = randomIntFromInterval(-10, 10)
    }
    newRotations.push(rnd)
  }
  return newRotations;
}

export default setRndRotations;