import './quizDialog.css';
import './quizDialogMobile.css';
import './quizDialogTablet.css'
import {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../firebase";
import {quizConverter} from "../../objects/Quiz";

const QuizDialog = (props) => {
  let open = props.open;
  let setOpen = props.setOpen;

  const [wednesdays, setWednesdays] = useState([]);

  const [name, setName] = useState("");
  const [people, setPeople] = useState('');
  const [time, setTime] = useState("6:45pm");
  const [date, setDate] = useState("");
  const [currentlySoldOut, setCurrentlySoldOut] = useState([])
  const [soldOut, setSoldOut] = useState(false);

  const getCurrentQuizzes = async () => {
    let objs = []
    const querySnapshot = await getDocs(collection(db, "quiz"));
    querySnapshot.forEach((doc) => {
      objs.push(quizConverter.fromFirestore(doc));
    });
    if (objs.length > 0) {
      setCurrentlySoldOut(objs)
    }
  }

  useEffect(() => {
    getCurrentQuizzes();
  }, []);

  useEffect(() => {
    if (wednesdays.length > 0) {
      setDate(wednesdays[0]);
    }
  }, [wednesdays]);

  useEffect(() => {
    if (date.includes("[SOLD OUT]")) {
      setSoldOut(true)
    } else {
      setSoldOut(false)
    }
  }, [date]);

  useEffect(() => {
    if (wednesdays.length === 0 && currentlySoldOut.length > 0) {
      let wednesdays = getNext10Wednesdays();
      if (currentlySoldOut.length > 0 && wednesdays.length > 0) {
        for (let i = 0; i < wednesdays.length; i++) {
          for (let j = 0; j < currentlySoldOut.length; j++) {
            if (currentlySoldOut[j].date === wednesdays[i] && currentlySoldOut[j].soldOut) {
              wednesdays[i] = wednesdays[i] + " [SOLD OUT]"
            }
          }
        }
      }
      setWednesdays(wednesdays)
    }
  }, [currentlySoldOut, wednesdays]);

  return (
    <div className={'quizDialogContainer'} style={{opacity: open ? '100%' : '0%', pointerEvents: open ? 'visiblePainted' : 'none'}} onClick={() => {
      setOpen(false)
    }}>
      <div className={'quizDialogInnerContainer'} style={{marginTop: open ? '-50px' : '200vh'}} onClick={(e) => {e.stopPropagation();}}>
        <h1>BOOK A QUIZ TABLE</h1>
        <div className={'quizFieldsContainer'}>
          <input onChange={(event) => {
            setName(event.target.value);
          }} placeholder={"Name for booking"} value={name}></input>
          <input type={"number"} placeholder={"Team size (Max 6 people per team)"} min={1} max={6} onChange={(event) => {
            setPeople(event.target.value);
          }} value={people}></input>
          <select defaultValue={'6:45pm'} id={'time'} onChange={(event) => {
            setTime(event.target.value);
          }} value={time}>
            <option value={"6:45pm"}>6:45pm</option>
            <option value={"7:00pm"}>7:00pm</option>
            <option value={"7:15pm"}>7:15pm</option>
            <option value={"7:30pm"}>7:30pm</option>
            <option value={"7:45pm"}>7:45pm</option>
          </select>
          {/*<input type="date" id="expiry" name="expiry" required pattern="\d{4}-\d{2}-\d{2}"/>*/}
          <select id={'wednesdays'} onChange={(event) => {
            setDate(event.target.value);
          }} value={date}>
            {wednesdays.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
          <div className={'quizBtnContainer'}>
            <a
              href={`mailto:info@mosaictap.com?subject=Quiz Booking for ${name}&body=Name: ${name}%0D%0ANo. of people: ${people}%0D%0ATime: ${time}%0D%0ADate: ${date}`}>
              <button className={'adminBtn quizBtn'} disabled={soldOut}>SEND EMAIL</button>
            </a>
            <div style={{fontFamily: `Bely`, fontStyle: `oblique`}}>
              OR
            </div>
            <a>
              <button onClick={() => {
                navigator.clipboard.writeText(`Quiz Booking for ${name}\nName: ${name}\nNo. of people: ${people}\nTime: ${time}\nDate: ${date}`).then(r => window.open(`https://ig.me/m/mosaictap`, "_blank"));
              }} className={'adminBtn quizBtn'} disabled={soldOut}>
                <div>INSTAGRAM DM</div>
                <div className={'instaBtnSecondLine'}>(Info will be copied to your clipboard!)</div>
              </button>
            </a>

          </div>
        </div>
      </div>
    </div>
  )
}

const getNext10Wednesdays = () => {
  const wednesdays = [];
  const today = new Date(Date.now() - 86400000);
  let day = today.getDay();
  let diff = (3 + 7 - day) % 7; // 3 represents Wednesday (0 is Sunday)

  if (diff === 0) {
    diff = 7; // If today is Wednesday, start from next Wednesday
  }

  let nextWednesday = new Date(today);
  nextWednesday.setDate(today.getDate() + diff);

  for (let i = 0; i < 10; i++) {
    let dateString = new Date(nextWednesday).toDateString()
    wednesdays.push(dateString);
    nextWednesday.setDate(nextWednesday.getDate() + 7);
  }

  return wednesdays;
};

export default QuizDialog;
export {getNext10Wednesdays}