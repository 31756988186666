import './tap.css';
import './tapMobile.css';
import './tapTablet.css';

import {Frame1, Frame2, Frame4, Frame3} from "../../../imgs";
import {useEffect, useLayoutEffect, useState} from "react";

const Tap = (props) => {
  let tapsOn = props.tapsOn;
  let setTapsOn = props.setTapsOn;
  let beers = props.beers;
  let index = props.index;
  let edge = props.edge;

  let [currentFrame, setCurrentFrame] = useState(0);
  let frames = [Frame1, Frame2, Frame3, Frame4]

  const pourBeer = (index) => {
    let newTapsOn = []
    for (let i = 0; i < tapsOn.length; i++) {
      if (i === index) {
        newTapsOn.push(!tapsOn[i]);
      } else {
        newTapsOn.push(tapsOn[i]);
      }
    }
    setTapsOn(newTapsOn)
  }

  useLayoutEffect(() => {
    if (!tapsOn[index]) {
      if (currentFrame > 0) {
        setTimeout(() => {
          setCurrentFrame(currentFrame - 1);
        }, 50)
      }
    } else {
      if (currentFrame < 3) {
        setTimeout(() => {
          setCurrentFrame(currentFrame + 1);
        }, 50)
      }
    }
  }, [tapsOn, currentFrame]);

  return (
    <div className={"grid-item tap-and-liquid"}>
      <img key={`tapImg${currentFrame}`} onClick={() => {
        pourBeer(index)
      }} className={edge ? 'tap tap-edge' : 'tap'} src={frames[currentFrame]} alt={`Beer tap for ${beers[index].name}`}/>
    </div>
  )
}

export default Tap;